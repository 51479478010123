import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import rolesReducer from './rolesReducer';
import usersReducer from './usersReducer';
import snackbarReducer from './snackbarReducer';
import templatesReducer from './templatesReducer';
import localesReducer from './localesReducer';
import scopesReducer from './scopesReducer';
import criterionTemplatesReducer from './criterionTemplatesReducer';
import criterionTypesReducer from './criterionTypesReducer';
import productsReducer from './productsReducer';
import mediaReducer from './mediaReducer';
import nodeDefinitionsReducer from './nodeDefinitionsReducer';
import shootingTemplatesReducer from './shootingTemplatesReducer';
import shootingTemplateNodesReducer from './shootingTemplateNodesReducer';

const rootReducer = combineReducers({
  account: accountReducer,
  users: usersReducer,
  roles: rolesReducer,
  notifications: snackbarReducer,
  templates: templatesReducer,
  locales: localesReducer,
  scopes: scopesReducer,
  criterionTemplates: criterionTemplatesReducer,
  criterionTypes: criterionTypesReducer,
  products: productsReducer,
  media: mediaReducer,
  nodeDefinitions: nodeDefinitionsReducer,
  shootingTemplates: shootingTemplatesReducer,
  shootingTemplateNodes: shootingTemplateNodesReducer
});

export default rootReducer;
