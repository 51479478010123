import React from 'react';
import PropTypes from 'prop-types';

function Logo({
  dark,
  ...rest
}) {
  const url = dark ? '/static/logo-dark.svg' : '/static/logo.svg';

  return (
    <img
      alt="Logo"
      src={url}
      width="150"
      {...rest}
    />
  );
}

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dark: false
};

export default Logo;
